<script>
export default {
  name: 'Pagination',
  components: {
    Action: () => import('@/components/general/Action')
  },

  props: {
    pageCount: {
      type: Number,
      default: 0
    },

    activePage: {
      type: Number,
      default: 0
    },

    prevPage: {
      type: Object,
      default: null
    },

    nextPage: {
      type: Object,
      default: null
    },

    firstPage: {
      type: Object,
      default: null
    },

    lastPage: {
      type: Object,
      default: null
    },

    dark: {
      type: Boolean,
      default: false
    },

    activeFirstLast: {
      type: Boolean,
      default: true
    },

    scrollEnabled: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  methods: {
    scrollToElem (elem) {
      if (this.scrollEnabled) {
        const top = elem ? elem.offsetTop : 0

        setTimeout(() => {
          window.scroll({
            top: top,
            behavior: 'smooth'
          })
        }, 100)
      }
    },

    prevAction () {
      this.$emit('previousPage')
      this.scrollToElem(null)
    },

    nextAction () {
      this.$emit('nextPage')
      this.scrollToElem(null)
    },

    lastPageAction () {
      this.$emit('lastPage')
      this.scrollToElem(null)
    },

    firstPageAction () {
      this.$emit('firstPage')
      this.scrollToElem(null)
    },

    getButtonLeftType () {
      return this.prevPage ? null : 'button'
    },

    getButtonRightType () {
      return this.nextPage ? null : 'button'
    },

    getButtonFirstType () {
      return this.firstPage ? null : 'button'
    },

    getButtonLastType () {
      return this.lastPage ? null : 'button'
    }
  }
}
</script>

<template>
  <div
    v-if="pageCount >= 2"
    class="pagination"
    :class="{'theme-dark': dark}"
  >
    <Action
      v-if="activeFirstLast"
      :text="$t('global:begin')"
      icon="keyboard_backspace"
      class="pagination-btn pagination-left pagination-first"
      :type="getButtonFirstType()"
      :url="firstPage"
      flat
      :dark="dark"
      :disabled="activePage === 1"
      @click="firstPageAction"
    />
    <Action
      icon="keyboard_backspace"
      class="pagination-btn pagination-left"
      :type="getButtonLeftType()"
      :url="prevPage"
      flat
      :dark="dark"
      :disabled="activePage === 1"
      @click="prevAction"
    />
    <div class="pagination-pages-container">
      <span
        class="pagination-pages"
        v-html="$t('global:pagination.pages', {'num1': activePage, 'num2': pageCount})"
      />
    </div>
    <Action
      icon="keyboard_backspace"
      class="pagination-btn pagination-right"
      :type="getButtonRightType()"
      :url="nextPage"
      icon-right
      flat
      :dark="dark"
      :disabled="activePage === pageCount"
      @click="nextAction"
    />
    <Action
      v-if="activeFirstLast"
      :text="$t('global:end')"
      icon="keyboard_backspace"
      class="pagination-btn pagination-right pagination-last"
      :type="getButtonLastType()"
      :url="lastPage"
      icon-right
      flat
      :dark="dark"
      :disabled="activePage === pageCount"
      @click="lastPageAction"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/pagination.css"></style>
